import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(({ breakpoints }) => ({
  container: {
    margin: '0 auto',
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    '&::-webkit-scrollbar': {
      display: 'none',
    },
    '-ms-overflow-style': 'none',
    scrollbarWidth: 'none',
    gap: rem(16),
    [breakpoints.up('md')]: {
      display: 'grid',
      gridTemplateColumns: `repeat(2, 1fr)`,
    },
    [breakpoints.up('lg')]: {
      display: 'grid',
      gridTemplateColumns: `repeat(4, 1fr)`,
    },
    justifyItems: 'center',
  },
}));
