import { FC } from 'react';
import { useIntl } from 'react-intl';

import ResourceItem, {
  ResourceItemProps,
} from '@/components/LandingMozza/LandingSeo/common/Resources/ResourcesGrid/ResourceItem/ResourceItem';
import { SEODefaultChunks } from '@/generic//SEOFormattedMessage/SEOFormattedMessage';

import messages from './messages';

import useStyles from './styles';

type ReviewsSliderProps = {
  resources: ResourceItemProps[];
};

const ResourcesGrid: FC<ReviewsSliderProps> = ({ resources }) => {
  const { classes } = useStyles();
  const { formatMessage } = useIntl();

  return (
    <div className={classes.container}>
      {resources.map(({ id, imageUrl, link }) => (
        <ResourceItem
          key={id}
          id={id}
          title={formatMessage({ ...messages[id], ...SEODefaultChunks })}
          imageUrl={imageUrl}
          link={link}
        />
      ))}
    </div>
  );
};

export default ResourcesGrid;
