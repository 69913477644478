import { defineMessages } from 'react-intl';

export default defineMessages({
  titleSubject: {
    id: 'landingMozza.landingSeo.landingSubjectOnline.SeoLinksSubjectOnlineTemplate.titleSubject',
    defaultMessage: 'Matières enseignées',
  },
  titleLevel: {
    id: 'landingMozza.landingSeo.landingSubjectOnline.SeoLinksSubjectOnlineTemplate.titleLevel',
    defaultMessage: 'Niveaux scolaires assurés',
  },
});
