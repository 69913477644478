import React, { FC } from 'react';
import { NameCode, replaceAndElide } from '@les-sherpas/sherpas-toolbox';

import Breadcrumb from '@/components/LandingMozza/LandingSeo/common/Breadcrumb/Breadcrumb';
import BreadcrumbItem from '@/components/LandingMozza/LandingSeo/common/Breadcrumb/BreadcrumbItem/BreadcrumbItem';

type Props = {
  subject: NameCode;
};
const Breadcrumbs: FC<Props> = ({ subject }) => {
  const labelSubject = `Cours de {subject}`;
  const labelSubjectOnline = `Cours de {subject} en ligne`;

  const origin =
    typeof window === 'undefined'
      ? process.env.NEXT_PUBLIC_FRONT_URL
      : window.location.origin;

  return (
    <Breadcrumb>
      <BreadcrumbItem href={origin} content="1" label="Cours particuliers" />
      <BreadcrumbItem
        href={`${origin}/cours/${subject.code}`}
        content="2"
        label={replaceAndElide(labelSubject, { subject: subject.name })}
      />
      <BreadcrumbItem
        content="3"
        label={replaceAndElide(labelSubjectOnline, { subject: subject.name })}
        isLastElement
      />
    </Breadcrumb>
  );
};

export default Breadcrumbs;
