import { FC } from 'react';
import { useIntl } from 'react-intl';
import { PublicGetSubjectOnlinePageResponse } from '@les-sherpas/sherpas-toolbox';

import SeoHeader from '@/components/LandingMozza/common/SeoHeader/SeoHeader';
import SeoTable from '@/components/LandingMozza/common/SeoTable/SeoTable';

import messages from './messages';

type Props = {
  links: PublicGetSubjectOnlinePageResponse['links'];
};

const SeoLinksSubjectOnlineTemplate: FC<Props> = ({ links }) => {
  const { formatMessage } = useIntl();
  const { subjects, levels } = links;

  return (
    <>
      <SeoHeader />
      <SeoTable
        links={subjects}
        title={formatMessage(messages.titleSubject)}
        type="subject"
      />
      <SeoTable
        links={levels}
        title={formatMessage(messages.titleLevel)}
        type="level"
      />
    </>
  );
};

export default SeoLinksSubjectOnlineTemplate;
