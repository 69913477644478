import rem from 'polished/lib/helpers/rem';
import { makeStyles } from 'tss-react/mui';

export default makeStyles()(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    gap: rem(16),
    maxWidth: rem(300),
  },
  imageContainer: {
    position: 'relative',
    width: rem(300),
    aspectRatio: 1 / 1,
  },
  image: {
    borderRadius: rem(16),
    objectFit: 'cover',
  },
}));
